/* .content {
    border: 1px solid #ddd;
} */

.mansory {
    display              : grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows   : repeat(6, 1fr);
    gap                  : 1rem;
    margin               : 1rem 0;
}

.itemGrid1 {
    grid-row  : 1 / 2;
    background: url('../../Images/itemGrid1.jpg');
}

.itemGrid2 {
    grid-row  : 2 / 3;
    background: url('../../Images/itemGrid2.jpg');
}

.itemGrid3 {
    grid-row   : 1 / 3;
    grid-column: 2 / 4;
    background : url('../../Images/itemGrid3.jpg');
}

.itemGrid4 {
    grid-row   : 3 / 5;
    grid-column: 1 / 2;
    background : url('../../Images/itemGrid4.jpg');
}

.itemGrid5 {
    grid-row   : 3 / 4;
    grid-column: 2 / 3;
    background : url('../../Images/itemGrid5.jpg');
}

.itemGrid6 {
    grid-row   : 3 / 4;
    grid-column: 3 / 4;
    background : url('../../Images/itemGrid6.jpg');
}

.itemGrid7 {
    grid-row   : 4 / 5;
    grid-column: 2 / 3;
    background : url('../../Images/itemGrid7.jpg');
}

.itemGrid8 {
    grid-row   : 4 / 6;
    grid-column: 3 / 4;
    background : url('../../Images/itemGrid8.jpg');
}

.itemGrid9 {
    grid-row   : 5 / 7;
    grid-column: 1 / 3;
    background : url('../../Images/itemGrid9.jpg');
}

.itemGrid10 {
    grid-row   : 6 / 7;
    grid-column: 3 / 4;
    background : url('../../Images/itemGrid10.jpg');
}

.bgGrid {
    max-width        : 100%;
    min-height       : 200px;
    background-size  : cover;
    background-repeat: no-repeat;
    border           : 3px solid transparent;
    filter           : grayscale(100%);
    box-sizing       : border-box;
}

.bgGrid:hover {
    filter: grayscale(0%);
    border: 3px solid #000;
}

@media (max-width: 767.98px) {
    .mansory {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows   : repeat(5, 1fr);
        gap                  : .5rem;
        margin               : .5rem;
    }

    .itemGrid1 {
        grid-row: 1 / 2;
    }

    .itemGrid2 {
        grid-row   : 1 / 2;
        grid-column: 2 / 3;
    }

    .itemGrid3 {
        grid-row   : 2 / 3;
        grid-column: 1 / 2;
    }

    .itemGrid4 {
        grid-row   : 2 / 3;
        grid-column: 2 / 3;
    }

    .itemGrid5 {
        grid-row   : 3 / 4;
        grid-column: 1 / 2;
    }

    .itemGrid6 {
        grid-row   : 3 / 4;
        grid-column: 2 / 3;
    }

    .itemGrid7 {
        grid-row   : 4 / 5;
        grid-column: 1 / 2;
    }

    .itemGrid8 {
        grid-row   : 4 / 5;
        grid-column: 2 / 3;
    }

    .itemGrid9 {
        grid-row   : 5 / 6;
        grid-column: 1 / 2;
    }

    .itemGrid10 {
        grid-row   : 5 / 6;
        grid-column: 2 / 3;
    }
}

@media (max-width: 575.98px) {
    .mansory {
        grid-template-columns: 1fr;
        grid-template-rows   : repeat(10, 1fr);
    }

    .itemGrid1 {
        grid-row: 1 / 2;
    }

    .itemGrid2 {
        grid-row   : 2 / 3;
        grid-column: 1;
    }

    .itemGrid3 {
        grid-row   : 3 / 4;
        grid-column: 1;
    }

    .itemGrid4 {
        grid-row   : 4 / 5;
        grid-column: 1;
    }

    .itemGrid5 {
        grid-row   : 5 / 6;
    }

    .itemGrid6 {
        grid-row   : 6 / 7;
        grid-column: 1;
    }

    .itemGrid7 {
        grid-row   : 7 / 8;
    }

    .itemGrid8 {
        grid-row   : 8 / 9;
        grid-column: 1;
    }

    .itemGrid9 {
        grid-row   : 9 / 10;
    }

    .itemGrid10 {
        grid-row   : 10 / 11;
        grid-column: 1;
    }
}