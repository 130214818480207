.boxCardFanz {
    border: 1px solid #eee;
    border-radius: 5px;    
}

.headerCardFanz {
    background: linear-gradient(45deg, rgb(45, 223, 255), rgb(241, 255, 51));
    height: 150px;
    position: relative;
}

.mansoryCardFanz {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.boxImgCardFanz {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
}

.imgCardFanz {
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
}

.descCardFanz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 150px;
    padding: 3rem 1rem 1rem;
    background: radial-gradient(ellipse, #fff 30%, #eee);
}

.dataCardFanz {
    font-size: 1.4rem;
    text-align: center;
}

.dataCardFanz small {
    font-size: 1rem;
}

.iconCardFanz {
    display: flex;
    justify-content: space-around;
    width: 100%;
}