.mansoryCardFanz {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin: 0 10px;
}

@media (max-width: 767.98px) {
    .mansoryCardFanz {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}