.header {
  display        : flex;
  justify-content: space-around;
  align-items    : center;
  min-height     : 150px;
}

.header ul {
  display        : flex;
  justify-content: space-between;
  min-width      : 300px;
}

.header ul li {
  list-style: none;
}

.header a {
  text-decoration: none;
  display        : block;
  color          : #000;
  border         : 2px solid #000;
  text-transform : uppercase;
  padding        : 12px 20px;
  margin         : 10px;
  transition     : color 0.4s linear;
  position       : relative;
}

.header a:hover {
  color: #fff;
}

.header a::before {
  content                   : "";
  position                  : absolute;
  left                      : 0;
  top                       : 0;
  width                     : 100%;
  height                    : 100%;
  background                : #000;
  z-index                   : -1;
  transition                : transform 0.5s;
  transform-origin          : 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
}

.navigationBtn::before {
  transform: scaleY(0);
}

.navigationBtn:hover::before {
  transform: scaleY(1);
}

.header .logoBtn {
  font-size: 2rem;
}

.logoBtn::before {
  transform: scaleX(0);
}

.logoBtn:hover::before {
  transform: scaleX(1);
}

.header .active {
  color     : #fff;
  background: #000;
}

@media (max-width: 575.98px) {
  .header ul {
    flex-direction: column;
    min-width     : 200px;
    text-align    : center;
  }
}

@media (max-width: 767.98px) {
  .header {
    flex-direction: column;
  }
}