.formButton {
    border: 0 solid transparent;
    border-top: 10px solid #000;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 250ms all ease;
    width: 100%;
}

.formButton:hover {
    border-top: 10px solid #ccc;
    background: #000;
    color: #fff;
    transition: 250ms all ease;
}