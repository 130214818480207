.label {
    display: block;
    padding: .3rem 0;
    margin-left: 10px;
    font-weight: bold;
}

.boxInput {
    margin: 1rem 0;
    background: #f4f4f4;
    padding: 10px;
    border-top: 10px solid #000;
}

.input {
    border: 0 solid transparent;
    border-bottom: 1px solid #000;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}