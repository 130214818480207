.footer {
    min-height: 150px;
    padding   : 1rem;
}

.footer ul {
    display        : flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.footer li {
    list-style: none;
}

.navigationFooterBtn {
    text-decoration: none;
    text-transform : uppercase;
    font-weight    : bold;
    font-size      : 3rem;
    letter-spacing : -5px;
    color          : #000;
    padding        : 10px;
    transition     : 500ms all ease;
}

.navigationFooterBtn:hover {
    color     : #fff;
    background: #000;
    transition: 500ms all ease;
}

.divisionBar {
    width     : 50%;
    height    : 10px;
    background: #000;
    margin    : 20px auto;
}

.footer .active {
    color     : #fff;
    background: #000;
}

.developed {
    text-align: center;
}

.developed a {
    text-decoration: none;
    font-weight    : bold;
    color          : #000;
    transition     : 250ms all ease;
    font-size      : 1.2rem;
}

.developed a:hover {
    color     : rgb(255, 98, 67);
    transition: 250ms all ease;
}

.developed a:hover svg {
    transform : scale(1.3);
    transition: 250ms all ease;
}

@media (max-width: 575.98px) {
    .footer ul {
        display        : flex;
        flex-direction: column;
        text-align: center;
    }

    .footer li {
        margin: 1rem 0;
    }
  }